.App {
    text-align: left;

    --footer-height: 60px;

    word-break: keep-all;

    box-sizing: border-box;
    padding: 32px;

    width: 100vw;
    height: 100vh;
    height: 100dvh;

    a {
        color: rgb(150,150,150);
        font-weight: bold;
        font-style: italic;
        text-decoration: inherit;

        &::before {
            content: '↪ ';
        }
    }

    .RootBackground {
        position: absolute;
        top: 0; left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;

        img {
            position: absolute;
            top: 0; left: 0;
            width: 100%;
            height: 100%;

            object-fit: cover;
            object-position: center;

            &#bg_low_res {
                filter: sepia(10);
            }

            &#bg_high_res {
                transition: 15000ms;

            }
        }
    }

    .Body {
        position: relative;
        height: 100%;
        overflow: auto;
        color: white;
        text-shadow: 0 0 5px rgba(0,0,0,0.8);

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 32px;
        
        font-family: "Montserrat", sans-serif;
        
        &::-webkit-scrollbar { display: none; }
        &::-webkit-scrollbar-thumb { display: none; }

        .Headline {

            top: 0; left: 0;
            width: 100%;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            font-size: 2rem;
            font-style: italic;
        }

        .Content {
            width: 100%;
            transition: max-height 500ms ease;

            &.Fold {
                max-height: 0; opacity: 0;
            }

            .Section {

                .SectionHeader {
                    font-size: 1.5rem;
                    font-weight: bold;
                }

                .SectionBody {
                    width: 100%;
                    padding-top: 16px; padding-bottom: 16px;
                    max-height: 1500px; overflow-y: hidden;
                    transition: max-height 500ms ease;

                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: stretch;
                    gap: 16px;

                    &.FlexRow {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
    
                        gap: 16px;
                    }
                }

                &.Fold .SectionHeader {
                    cursor: pointer;
                    font-weight: 400;
                }

                &.Fold .SectionBody {
                    max-height: 0; opacity: 0;
                }

                .Letter {
                    color: white;
                    font-size: 1.2rem;
                    font-style: italic;
                    background-color: rgba(0,0,0,0.4);
                    padding: 16px;
                    border-top-left-radius: 16px;
                    border-bottom-right-radius: 16px;
                    white-space: pre-wrap;
                }

                .InputArea {
                    width: 100%;
                    box-sizing: border-box;
                    resize: vertical;

                    border-radius: 8px;
                    padding: 8px;
                    background-color: rgb(255,255,255);

                    font-size: 1rem;

                    font-family: "Montserrat", sans-serif;
                }

                .Button {
                    border: 0;
                    width: 100%;

                    border-radius: 8px;
                    padding: 8px;

                    background-color: rgba(255,255,255,1);
                    color: black;
                    transition: 200ms;

                    &:disabled {
                        color: rgb(200,200,200);
                    }

                    &:active:not(:disabled) {
                        background-color: rgba(122,122,122,1);
                        color: white;
                    }
                }
            }

            .Toggle {
                width: 50px; height: 50px;
                border-radius: 50%;
                background-color: rgba(255,255,255,0.5);

                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                cursor: pointer;

                &:active {
                    background-color: rgba(255,255,255,0.8);
                }

                &.Active {
                    background-color: rgb(255,2552,255);
                    color: black;

                }
            }
        }
    }
    footer {
        text-align: center;
        position: absolute;
        bottom: 24px; left: 0;
        width: 100%;
    }

}
